import { useCallback, useEffect, useState } from 'react';

const useBreakpoint = () => {
    const [width, setWidth] = useState(window.outerWidth);
    useEffect(() => {
        function updateWidth() {
            setWidth(window.outerWidth);
        }

        window.addEventListener('resize', updateWidth);
        return () => window.removeEventListener('resize', updateWidth);
    }, [])

    const getBreakpoint = useCallback(
        () => window.getComputedStyle(document.body, '::after')
            .content.replace(/"/g, ''),
        []
    );

    const [breakpoint, setBreakpoint] = useState(getBreakpoint());

    const breakpoints = (() => {
        return ['--bp-xs', '--bp-sm', '--bp-md', '--bp-lg', '--bp-xl'].reduce((bps, bp) => {
            return { ...bps, [bp.replace('--bp-', '')]: Number.parseInt(getComputedStyle(document.body).getPropertyValue(bp)) };
        }, {});
    })();

    useEffect(() => {
        setBreakpoint(getBreakpoint());
    }, [width, getBreakpoint]);

    function isEqualTo(size) { return width === breakpoints[size]; }
    function isGreaterThan(size) { return width > breakpoints[size]; }
    function isGreaterThanOrEqualTo(size) { return width >= breakpoints[size]; }
    function isLowerThan(size) { return width < breakpoints[size]; }
    function isLowerThanOrEqualTo(size) { return width <= breakpoints[size]; }

    return {
        width,
        breakpoint,
        breakpoints,
        isEqualTo,
        isGreaterThanOrEqualTo,
        isGreaterThan,
        isLowerThanOrEqualTo,
        isLowerThan,
    };
};

export default useBreakpoint;
