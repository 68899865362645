import { useEffect, useState } from 'react';

import { Grid, ImageSlide, Layout, useModal } from '../Components/index.js';

let images = [];

const GalleryPage = () => {
    const [galleryImages, setGalleryImages] = useState(images);

    useEffect(() => {
        images = [];
        for (let i = 1; i < 26; i += 1) {
            images.push({ src: `/img/gallery/Gallery${i}.jpg` });
        }

        setGalleryImages(images);
    }, []);

    const modal = useModal({ size: 'sm', template: ImageSlide });
    const { show } = modal;

    const handleImageClicked = (src) => {
        show({ images: galleryImages, src });
    };

    return (
        <Layout className="stack-col-3" size="md">
            <h2>Gallery</h2>

            <Grid gap="md" placeContent="center" width="235px">
                {galleryImages.map((image) => (
                    <div
                        onClick={() => handleImageClicked(image.src)}
                        style={{
                            backgroundImage: `url(${image.src})`,
                            backgroundPosition: 'center center',
                            backgroundSize: 'cover',
                            cursor: 'pointer',
                            width: '235px',
                            height: '150px',
                        }}
                    />
                ))}
            </Grid>
        </Layout>
    );
};

export default GalleryPage;
