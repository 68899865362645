import ReactDOM from 'react-dom';
import { createRef, useEffect, useState } from 'react';
import { isFunction } from 'lodash-es';

import { Observable } from '../index.js';

import css from './Modal.module.scss';

export const modalObservable = new Observable();

const defaultOptions = {
    dismissable: true, // When true, the modal is dismissed on backdrop click
};

const ModalContainer = (props) => {
    const [modalProps, setModalProps] = useState({});
    const [options, setOptions] = useState({});

    useEffect(() => {
        const handle = (props) => {
            setOptions(props.options || {});
            setModalProps(props.modal || {});
        };

        modalObservable.subscribe(handle);

        return () => modalObservable.unsubscribe(handle);
    }, []);

    if (!modalProps.isVisible) return null;

    const ModalComponent = options.template;

    return ModalComponent
        ? (<Modal modal={modalProps}>
            <ModalComponent modal={modalProps} options={options} {...modalProps.context} />
        </Modal>)
        : null;
}

const Modal = (props) => {
    const { children, className, dismissable = true, footer, header, modal = {}, size = 'md', ...restProps } = props;
    const { context, hide, isVisible, template } = modal;

    const modalRef = createRef();
    const backdropRef = createRef();

    useEffect(() => {
        const backdrop = backdropRef.current;

        function clickOutsideToClose(event) {
            const isOutside = !modalRef.current?.contains(event.target);

            if (dismissable && isVisible && isOutside && isFunction(hide)) {
                modalObservable.notify({ isVisible: false });
            }
        }

        backdrop.addEventListener('click', clickOutsideToClose);

        return () => backdrop.removeEventListener('click', clickOutsideToClose);
    }, [isVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    if (!isVisible) return null;

    const classes = [css.modal];
    if (size) classes.push(css[`size-${size}`]);
    if (className) classes.push(className);

    return ReactDOM.createPortal(
        (<div className={css['modal-container']} ref={backdropRef}>
            <div className={classes.join(' ')} ref={modalRef} modal={modal} {...restProps}>
                {header && <Modal.Header>{header}</Modal.Header>}
                {isFunction(template) ? template(context) : template}
                {!template && children}
                {footer && <Modal.Footer>{footer}</Modal.Footer>}
            </div>
        </div>),
        document.getElementById('modal-portal'),
    );
};

const useModal = (options = {}) => {
    const parsedOptions = {
        ...defaultOptions,
        ...options,
    };

    const [context, setContext] = useState(parsedOptions.context || {});
    const [isVisible, setIsVisible] = useState(parsedOptions.isVisible || false);

    const show = async (newContext) => {
        setContext(newContext);
        setIsVisible(true);
        if (isFunction(parsedOptions.onShow)) parsedOptions.onShow();

        modalObservable.notify({
            options: parsedOptions,
            modal: {
                hide,
                show,
                context: newContext || context || parsedOptions.context || {},
                isVisible: true,
            }
        });
    };

    const hide = () => {
        if (isFunction(parsedOptions.onHide)) parsedOptions.onHide();

        modalObservable.notify({ isVisible: false });
    };

    return { Modal, context, isVisible, hide, show };
};

export default useModal;
export { ModalContainer, Modal };
