import axios from 'axios';

const {
    REACT_APP_API_AUTH_USERNAME,
    REACT_APP_API_AUTH_PASSWORD,
    REACT_APP_API_URL,
} = process.env;

const api = axios.create({
    auth: {
        username: REACT_APP_API_AUTH_USERNAME,
        password: REACT_APP_API_AUTH_PASSWORD,
    },
    baseURL: REACT_APP_API_URL,
    withCredentials: true,
});

const Api = () => {
    return api;
};

const wrap = (method) => {
    return (...params) => {
        return api[method](...params)
            .then((res) => res.data)
            .catch((err) => {
                return Promise.reject(err?.response?.data || err.toJSON());
            });
    };
};

Api.get = wrap('get');
Api.post = wrap('post');
Api.put = wrap('put');
Api.delete = wrap('delete');

export default Api;
