import { useEffect, useState } from 'react';

import { Carousel } from '../index.js';

import css from './ImageSlide.module.scss';

const ImageSlide = (props) => {
    const { images = [], modal, src } = props;

    const [currentSlideIndex, setCurrentSlideIndex] = useState(images.findIndex((image) => image.src === src));
    const [activeSrc, setActiveSrc] = useState(src);

    useEffect(() => {
        if (images?.[currentSlideIndex]?.src) {
            setActiveSrc(images[currentSlideIndex].src)
        }
    }, [currentSlideIndex]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!images) return null;

        function escapeToClose(event) {
            if (event.key === 'Escape') {
                modal.hide();
            }
        }
        function navigate(event) {
            if (event.key === 'ArrowLeft') setCurrentSlideIndex(currentSlideIndex === 0 ? images.length - 1 : currentSlideIndex - 1);
            if (event.key === 'ArrowRight') setCurrentSlideIndex(currentSlideIndex === images.length - 1 ? 0 : currentSlideIndex + 1);
        }

        window.addEventListener('keydown', escapeToClose);
        window.addEventListener('keydown', navigate);
        return () => {
            window.removeEventListener('keydown', escapeToClose);
            window.removeEventListener('keydown', navigate);
        };
    }, [currentSlideIndex]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={css.slide}>
            <div className={css.close} onClick={() => modal.hide()}>x</div>
            {images
                ? (<Carousel initialSlide={currentSlideIndex} slides={images.map((image) => ({ ...image, clickToZoom: false }))} />)
                : (<img className={css.image} src={activeSrc} alt="" />)}
        </div>
    );
};

export default ImageSlide;
