import css from './Link.module.scss';

const Link = (props) => {
    const { children, className, external, href, ...restProps } = props;

    const rel = [];
    if (external) rel.push('noopener');

    const target = [];
    if (external) target.push('_blank');

    const classes = [css.link];
    if (className) classes.push(className);

    return (
        <a
            className={classes.join(' ').trim()}
            href={href}
            rel={rel.join(' ').trim()}
            target={target.join(' ').trim()}
            {...restProps}
        >
            {children}
        </a>
    );
};

export default Link;
