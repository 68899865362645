import { Image, Layout, useBreakpoint } from '../Components/index.js';

const OurStoryPage = () => {
    const { isGreaterThanOrEqualTo } = useBreakpoint();
    return (
        <Layout className="stack-col-3" size="md">
            <h2>Our story</h2>

            <div className="stack-col-3">
                <div className="stack-center">
                    <Image src="/img/our-story/our-story-1.jpg" alt="" height="600px" />
                    {isGreaterThanOrEqualTo('md') && (<Image src="/img/our-story/our-story-2.jpg" alt="" height="600px" />)}
                </div>

                <h3>Meet the team</h3>

                <p>Ridiculously Cool is owned and operated by brothers Yiannis and Andreas. This insanely talented Greek duo spend most of their time making epic electronic music that sounds like it belongs on Stranger Things. When they’re not making music, the pair can be found organising events and developing new projects in the studio alongside their newest addition to the team, Anja. After rehearsing at the studio for quite some time, she thought it was so ridiculously cool that she had to get involved in the marketing. Because of this, everything is written from a genuine musician's perspective! They are currently working on building a coworking space that will be finished in the coming months.</p>
            </div>

            <div className="stack-col-3">
                <div className="stack-center">
                    {!isGreaterThanOrEqualTo('md') && (<Image src="/img/our-story/our-story-2.jpg" alt="" height="600px" />)}
                </div>

                <h3>Built by musicians for musicians</h3>

                <p>Transforming a neglected warehouse (with a questionable past) to fully functional studios was no easy task. We spent countless sleepless nights to build Ridiculously Cool and we’re still tweaking and improving it. As professional musicians ourselves, we know how important it is to feel inspired when creating or performing music.</p>

                <p>Our goal is to provide a welcoming space for people who love music just as much as we do - that’s why we’ve put so much thought into every aspect. From our soundproofing to our ambient lighting, contact us and we’ll help you produce the best music possible.</p>
            </div>
        </Layout>
    );
};

export default OurStoryPage;
