import { Icon } from '../index.js';

import css from './IconButton.module.scss';

const IconButton = (props) => {
    const { className, name, ...restProps } = props;

    const classes = [css['icon-button']];
    if (className) classes.push(className);

    return (
        <Icon className={classes.join(' ').trim()} name={name} {...restProps} />
    );
};

export default IconButton;
