import { useEffect, useState } from 'react';
import { Address, Layout, Map, getLatLngFromAddress } from '../Components/index.js';

const POST_CODE = 'BN14 7DF';

const ContactUsPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [coordinates, setCoordinates] = useState();

    useEffect(() => {
        if (!isLoading && !coordinates) {
            setIsLoading(true);
            getLatLngFromAddress(POST_CODE)
                .then((coords) => setCoordinates(coords))
                .finally(() => setIsLoading(false));
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Layout className="stack-col-3" size="md">
            <h2>Contact us</h2>

            {coordinates && (
                <Map
                    center={coordinates}
                    failover="/img/map.png"
                    height="400px"
                >
                    <Map.Marker position={coordinates}>
                        <Address />
                    </Map.Marker>
                </Map>
            )}

            <Address />
        </Layout>
    );
};

export default ContactUsPage;
