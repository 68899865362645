import axios from 'axios';

const Http = () => {
    return axios;
};

const wrap = (method) => {
    return (...params) => {
        return axios[method](...params)
            .then((res) => res.data)
            .catch((err) => {
                console.error(err);
                return Promise.reject(err?.response?.data || err.toJSON());
            });
    };
};

Http.get = wrap('get');
Http.post = wrap('post');
Http.put = wrap('put');
Http.delete = wrap('delete');

export default Http;
