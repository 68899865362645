import { filter, forEach } from 'lodash-es';

/**
 * A Rudimentary implementation of the observer pattern
 */
class Observable {
    // eslint-disable-next-line no-undef
    observers = [];

    subscribe(fx) {
        this.observers = [...this.observers, fx];
    }

    unsubscribe(fx) {
        this.observers = filter(this.observers, otherFx => otherFx !== fx);
    }

    notify(data) {
        forEach(this.observers, observer => observer(data));
    }
}

export default Observable;
