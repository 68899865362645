import { Link } from '../index.js';

const address = 'Unit 4b, 15 Southcourt Rd, Worthing BN14 7DF';

const Address = () => {
    return (
        <div>
            <h3>Where to find us</h3>
            <div><strong>Address:</strong> <Link external href={`maps://maps.google.com/?daddr=${address}`}>{address}</Link></div>
            <div><strong>Phone:</strong> <Link external href="tel:07767675860">07767 675860</Link></div>
            <div><strong>Email:</strong> <Link external href="mailto:ridiculouslycoolstudios@gmail.com">ridiculouslycoolstudios@gmail.com</Link></div>
        </div>
    );
};

export default Address;
