import * as Ionic from 'react-icons/io5';

const Icon = (props) => {
    const { height, name, width, size = '32px', style = {}, ...restProps } = props;

    const IconElement = Ionic[name];

    const iconStyle = { ...style };
    if (size || width) iconStyle.width = width || size;
    if (size || height) iconStyle.height = height || size;

    return IconElement
        ? (<IconElement style={iconStyle} {...restProps} />)
        : null;
};

export default Icon;
