import css from './Image.module.scss';

const Image = (props) => {
    const { align = 'center', alt = '', className, src, size, width, height, style = {}, ...restProps } = props;

    const classes = [css.image];
    if (align) classes.push(css[align]);
    if (className) classes.push(className);

    const imageStyle = { ...style };
    if (width || size) imageStyle.maxWidth = width || size;
    if (height || size) imageStyle.maxHeight = height || size;

    return (
        <img
            alt={alt}
            className={classes.join(' ').trim()}
            src={src}
            style={imageStyle}
            {...restProps}
        />
    );
};

export default Image;
