import { useEffect, useState } from 'react';

import css from './TomCruise.module.scss';

const TOM_CRUISE_PROBABILITY = 0.05;
const COLOURS = [
    '#cc00ff',
    '#ff38d1',
    '#009fe3',
    '#00ccff',
    '#0dfef5',
    '#ffcc00',
    '#04f114',
];

const useTomCruise = () => {
    const tomCruiseStore = localStorage.getItem('tomCruise');
    const state = tomCruiseStore ? JSON.parse(tomCruiseStore) : {
        showTomCruiseLink: true,
        showTomCruise: false,
        tomCruiseModeEngaged: false,
    };

    const [audio, setAudio] = useState();
    const [backgroundColor, setBackgroundColor] = useState();
    const [cruiseClasses, setCruiseClasses] = useState([css.cruise]);
    const [showTomCruise, setShowTomCruise] = useState(state.showTomCruise);
    const [tomCruiseModeEngaged, setTomCruiseModeEngaged] = useState(state.tomCruiseModeEngaged);

    // Show/hide Tom Cruise
    useEffect(() => {
        setInterval(() => {
            setShowTomCruise(tomCruiseModeEngaged || Math.random() <= TOM_CRUISE_PROBABILITY)
        }, 1000);
    });

    useEffect(() => {
        if (audio && tomCruiseModeEngaged) {
            audio.loop = true;
            audio.play();
        } else if (audio && !tomCruiseModeEngaged) {
            audio.pause();
        }
    }, [audio, tomCruiseModeEngaged]);

    useEffect(() => {
        if (tomCruiseModeEngaged) {
            // Audio
            if (!audio) {
                setAudio(new Audio('/media/dd.mp3'));
            }
            // Background
            setTimeout(() => {
                const colorIndex = COLOURS.findIndex(color => color === backgroundColor);
                setBackgroundColor(COLOURS[colorIndex === (COLOURS.length - 1) ? 0 : (colorIndex + 1)]);
            });
        } else {
            setAudio(null);
            setBackgroundColor();
        }

        const interval = setInterval(() => {
            const classes = [css.cruise];
            const showCromTuise = Math.random() > (1 - TOM_CRUISE_PROBABILITY);
            if (showCromTuise || tomCruiseModeEngaged) classes.push(css.greet);
            setCruiseClasses(classes);
        }, 1500);
        const images = document.getElementsByTagName('img');
        [...images].forEach(image => {
            const { src } = image;
            if (!image.data) image.data = { src };
            image.classList[tomCruiseModeEngaged ? 'add' : 'remove'](css.bounce);
            image.src = tomCruiseModeEngaged ? '/img/crom-tuise.gif' : image.data.src;
        });
        return () => clearInterval(interval);
    }, [audio, backgroundColor, tomCruiseModeEngaged]);

    const handleTomCruiseModeClicked = () => {
        setTomCruiseModeEngaged(!tomCruiseModeEngaged);
    };

    if (showTomCruise) cruiseClasses.push(css.greet);


    return {
        TomCruise: () => <div className={cruiseClasses.join(' ')} onClick={handleTomCruiseModeClicked}></div>,
        tomCruiseModeEngaged,
    };
};

export { useTomCruise };
