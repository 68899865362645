import { Marker as LeafletMarker, MapContainer, Popup, TileLayer } from 'react-leaflet';

import markerIconPng from 'leaflet/dist/images/marker-icon.png'
import { Icon } from 'leaflet'

import { isArray, isNumber } from 'lodash-es';
import { useEffect, useState } from 'react';

import './leaflet-warning-fix.css';

import { Api, Image } from '../index.js';

import css from './Map.module.scss';

const CACHE = {};
const INITIAL_CENTER = [50.81902, -0.37693];

function isLatLng(point) {
    return isArray(point) && point.length === 2 && isNumber(point[0]) && isNumber(point[1]);
}

function getLatLngFromAddress(point) {
    return Api.post(`/geocode?point=${point}`)
        .then((res) => res.point || INITIAL_CENTER)
        .catch((err) => {
            console.error(err.message || 'Could not fetch the coordinates for this point');
        });
}

const Map = (props) => {
    const { center, children, className, failover, height, width, style = {} } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [mapCenter, setMapCenter] = useState(INITIAL_CENTER);

    useEffect(() => {
        if (!isLatLng(center) && !CACHE[center]) {
            setIsLoading(true);
            getLatLngFromAddress(center)
            .then((latLng) => {
                setMapCenter(latLng);
                setIsLoading(false);
                CACHE[center] = latLng;
            });
        } else if (CACHE[center] && CACHE[center] !== mapCenter) {
            setMapCenter(CACHE[center]);
            setIsLoading(false);
        } else if (center !== mapCenter) {
            setMapCenter(center);
            setIsLoading(false);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const classes = [css.map];
    if (className) classes.push(className);

    if (height) style.height = height;
    if (width) style.width = width;

    if (failover && isLoading) {
        return (
            <Image
                src={failover}
                style={{
                    ...style,
                    height: 'auto',
                    maxHeight: height,
                }}
            />
        );
    }
    return (
        <MapContainer
            center={mapCenter}
            className={classes.join(' ').trim()}
            scrollWheelZoom={true}
            style={style}
            zoom={16}
        >
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {isLoading ? null : children}
        </MapContainer>
    );
};

const Marker = (props) => {
    const { children, position } = props;

    const [markerPosition, setMarkerPosition] = useState();

    useEffect(() => {
        if (!isLatLng(position) && !CACHE[position]) {
            getLatLngFromAddress(position)
            .then((latLng) => setMarkerPosition(latLng));
        } else if (CACHE[position]) {
            setMarkerPosition(CACHE[position]);
        } else {
            setMarkerPosition(position);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return isLatLng(markerPosition)
        ? (
            <LeafletMarker
                icon={new Icon({iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41]})}
                position={markerPosition}
            >
                {children && (<Popup>{children}</Popup>)}
            </LeafletMarker>
        )
        : null;
};

Map.Marker = Marker;

export default Map;
export { Marker, getLatLngFromAddress };
