import { BrowserRouter } from 'react-router-dom';

import AppRoutes from './AppRoutes.js';
import { ModalContainer } from '../Components/Modal/useModal.js';

const App = () => {
    return (
        <BrowserRouter>
            <AppRoutes />
            <ModalContainer />
        </BrowserRouter>
    );
};

export default App;
