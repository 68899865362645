import css from './Grid.module.scss';

const Grid = (props) => {
    const {
        children,
        className,
        gap,
        height = 'auto',
        justifyContent,
        placeContent,
        style,
        width = 'auto',
        ...restProps
    } = props;

    const gridStyle = { ...(style || {}) };
    gridStyle.gridTemplateRows = `repeat(auto-fill, ${height})`;
    gridStyle.gridTemplateColumns = `repeat(auto-fill, ${width})`;

    if (justifyContent) gridStyle.justifyContent = justifyContent;

    const classes = [css.grid];
    if (gap) classes.push(css[`gap-${gap}`]);
    if (placeContent) classes.push(css[`place-content-${placeContent}`]);
    if (className) classes.push(className);

    return (
        <div className={classes.join(' ').trim()} style={gridStyle} {...restProps}>
            {children}
        </div>
    );
};

export default Grid;
