import { NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { IconButton, useBreakpoint } from '../index.js';

import css from './Navigation.module.scss';

const baseLinks = [
    { label: 'Home', to: '/', colour: 'vivid-sky-blue' },
    // TODO: Decide whether this will be updated or removed in the future
    // { label: 'Our Story', to: '/our-story', colour: 'green' },
    { label: 'Practice rooms', to: '/practice-rooms', colour: 'carolina-blue' },
    { label: 'DJ Booth', to: '/dj-booth', colour: 'green' },
    { label: 'Coworking & Events', to: '/coworking-and-events', colour: 'fuchsia' },
    { label: 'Gallery', to: '/gallery', colour: 'turquoise-blue' },
    { label: 'Contact us', to: '/contact-us', colour: 'electric-purple' },
];

const getLinkClass = (link) => {
    const isActive = window.location.pathname === link.to;
    const classes = [css.link, css[link.colour]];

    if (isActive) classes.push(css.active);

    const className = classes.join(' ').trim();

    return className;
};

const Navigation = () => {
    const initialShowTomCruiseState = Boolean(sessionStorage.getItem('showTomCruiseLink') !== 'false');

    const { isLowerThan } = useBreakpoint();
    const [links, setLinks] = useState(baseLinks);
    const [showTomCruiseLink, setShowTomCruiseLink] = useState(initialShowTomCruiseState ?? true);
    const [isExpanded, setIsExpanded] = useState(false);

    const isDocked = isLowerThan('md');

    const handleToggleNavClicked = () => {
        setIsExpanded(!isExpanded);
    };

    const handleDisappear = () => {
        sessionStorage.setItem('showTomCruiseLink', false);
        setShowTomCruiseLink(false);
    };

    useEffect(() => {
        setLinks(
            showTomCruiseLink
                ? [
                    ...baseLinks,
                    { label: 'Tom Cruise', onClick: () => handleDisappear(), colour: 'green' },
                ]
                : baseLinks,
        );
    }, [showTomCruiseLink]);

    const navElement = (classNames) => (
        <nav className={[css.navigation, classNames].join(' ').trim()}>
            {links.map((link) =>
                link.to
                    ? (
                        <NavLink className={getLinkClass(link)} key={link.to} to={link.to}>
                            <span className={css.text}>{link.label}</span>
                        </NavLink>
                    )
                    : (
                        <span className={getLinkClass(link)} key={link.to} onClick={link.onClick}>
                            <span className={css.text}>{link.label}</span>
                        </span>
                    )
            )}
        </nav>
    );

    const classes = [css.backdrop];
    if (isExpanded) classes.push(css.expanded)

    return isDocked
        ? (
            <div className={classes.join(' ').trim()}>
                {isExpanded && (
                    <div className={css['button-row']}>
                        <IconButton name="IoClose" onClick={handleToggleNavClicked} size="48px" />
                    </div>
                )}
                {!isExpanded && (<IconButton name="IoMenuOutline" onClick={handleToggleNavClicked} size="48px" />)}

                {isExpanded && navElement(css.docked)}
            </div>
        )
        : navElement()
};

export default Navigation;
