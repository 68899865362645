import { Carousel, Layout } from '../Components/index.js';

const images = [
    { clickToZoom: true, backgroundSrc: '/img/events/events-1.jpg', height: '400px' },
    { clickToZoom: true, backgroundSrc: '/img/events/events-2.jpg', height: '400px' },
];

const CoworkingAndEventsPage = () => {
    return (
        <Layout className="stack-col-3" size="md">
            <h2>Coworking &amp; events</h2>

            <Carousel slides={images} />

            <p>Our coworking and events space is just a few feet away from Worthing Train Station and is a great choice for those looking for a flexible and relaxed atmosphere. We've hosted some awesome events here in the past, such as networking evenings and AudioActive workshops. We're now transforming the space into a permanent hub of activity!</p>
            <p>Four permanent desks and a 2-person private office space will be available to hire on a monthly basis in the near future. With exposed ceiling beams and an industrial modern interior, this workspace is the perfect blank canvas for any creative or freelancer who needs a place to call home. Additionally, you'll have access to a full-sized snooker table and retro video games as well as a kitchenette and breakout area.</p>
            <p>We provide space for corporate or creative workshops and events such as exhibitions, classes, or training sessions.</p>
            <p>Please contact us to receive a tour of our Ridiculously Cool coworking and events space.</p>
        </Layout>
    );
};

export default CoworkingAndEventsPage;
