import { Grid, Layout, Link, useBreakpoint } from '../Components/index.js';

import css from './DjBoothPage.module.scss';

const DjBoothPage = () => {
    const { isGreaterThanOrEqualTo } = useBreakpoint();
    const gridWidth = isGreaterThanOrEqualTo('md') ? '45%' : '100%';

    return (
        <Layout className={css.page} size="md">
            <h2>DJ Room</h2>

            <Grid gap="md" justifyContent="center" width={gridWidth}>
                <div>
                    <img className={css.cover} src="/img/dj-booth/dj-booth-1.jpg" alt="DJ booth 1" title="" />
                </div>
                <div>
                    <img className={css.cover} src="/img/dj-booth/dj-booth-2.jpg" alt="DJ booth 2" title="" />
                </div>
            </Grid>

            <p>Suitable for up to 2 people, this brand-new setup is available from 9am-3pm Monday to Friday.  Whether you are a beginner or a pro, this suite is perfect to practise and even record your DJ Sets in a soundproofed environment.</p>
            <p>Rates:</p>

            <ul>
                <li>£25 per 2-hour slots (9-11, 11-1 &amp; 1-3).</li>
                <li>An additional £10 if you require an inhouse recording of your session, however, you may supply your own recording equipment free of charge</li>
            </ul>

            <p>Size: 64 square foot</p>

            <p> Equipment:</p>

            <ul>
                <li>2x Pioneer CDJ 3000s</li>
                <li>Pioneer CDJ 2000</li>
                <li>Pioneer DJM Mixer 850 4 Channel</li>
                <li>Speakers: Mackie TH15A Thump Active 15” V4 1300w</li>
                <li>Microphone</li>
                <li>Laptop stand</li>
            </ul>

            <p>Not included: Headphones, Laptop and USB sticks.</p>

            <p>Alternatively, if you are interested in learning how to DJ, we offer a month’s introductory but intensive course that spans over 4 2x hour sessions.  Click here for the information brochure.</p>

            <p>For all enquiries and bookings for the DJ Room please contact Sam directly by emailing <Link href="mailto:amusementaudio@gmail.com">amusementaudio@gmail.com</Link> or calling <Link href="tel:07784909195">07784909195</Link>.</p>

            <p>
                <Link download href="/media/121 DJ Tuition Course.pdf">Click here for more information</Link>
            </p>
        </Layout>
    );
};

export default DjBoothPage;
