import { Routes, Route } from 'react-router-dom';

import {
    HomePage,
    OurStoryPage,
    PracticeRoomsPage,
    DjBoothPage,
    CoworkingAndEventsPage,
    GalleryPage,
    ContactUsPage,
} from '../Pages/index.js';

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/our-story" element={<OurStoryPage />} />
            <Route path="/practice-rooms" element={<PracticeRoomsPage />} />
            <Route path="/dj-booth" element={<DjBoothPage />} />
            <Route path="/coworking-and-events" element={<CoworkingAndEventsPage />} />
            <Route path="/gallery" element={<GalleryPage />} />
            <Route path="/contact-us" element={<ContactUsPage />} />
        </Routes>
    )
};

export default AppRoutes;
