import { createRef } from 'react';

import { Address, Grid, Image, Link, Navigation, SocialLinks, useBreakpoint, useTomCruise } from '../index.js';

import css from './Layout.module.scss';

const Layout = (props) => {
    const { children, className, size, ...restProps } = props;

    const pageRef = createRef();

    const { TomCruise, tomCruiseModeEngaged } = useTomCruise({
        page: pageRef.current,
    });

    const { isGreaterThanOrEqualTo } = useBreakpoint();
    const topbarContent = isGreaterThanOrEqualTo('md')
        ? <div>Call us: <Link external href="tel:07767675860">07767 675860</Link> or book via <Link external href="https://www.facebook.com/messages/t/1994123504144067">facebook messenger</Link></div>
        : <div><small><Link external href="tel:07767675860">07767 675860</Link>, <Link external href="https://www.facebook.com/messages/t/1994123504144067">facebook messenger</Link></small></div>
    const footerGridItemWidth = isGreaterThanOrEqualTo('md') ? '40%' : '300px';
    const footerGridGap = isGreaterThanOrEqualTo('md') ? 'xxl' : 'md';
    const classes = [css.page];
    if (size) classes.push(css[`size-${size}`]);

    const contentClasses = [css.content];
    if (className) contentClasses.push(className);

    return (
        <div className={classes.join(' ').trim()} ref={pageRef} {...restProps}>
            <div className={css.topbar}>
                {topbarContent}
            </div>
            <div className={css.header}>
                <a href="/"><Image align="start" src="/img/logo.png" height="75px" /></a>
                <Navigation />
            </div>
            {tomCruiseModeEngaged && (<div className={css['tom-cruise-mode-banner']}>TOM CRUISE MODE ACTIVATED</div>)}
            <div className={contentClasses.join(' ').trim()}>{children}</div>
            <div className={css.footer}>
                <Grid className={css['footer-content']} gap={footerGridGap} width={footerGridItemWidth}>
                    <Address />
                    <SocialLinks />
                </Grid>
            </div>
            <TomCruise />
        </div>
    );
};

export default Layout;
export { default as useBreakpoint } from './useBreakpoint.js';
