import { Carousel, Layout } from '../Components/index.js';

const images = [
    { clickToZoom: true, backgroundSrc: '/img/gallery/Gallery6.jpg', height: '400px' },
    { clickToZoom: true, backgroundSrc: '/img/gallery/Gallery2.jpg', height: '400px' },
    { clickToZoom: true, backgroundSrc: '/img/gallery/Gallery11.jpg', height: '400px' },
    { clickToZoom: true, backgroundSrc: '/img/gallery/Gallery1.jpg', height: '400px' },
    { clickToZoom: true, backgroundSrc: '/img/gallery/Gallery8.jpg', height: '400px' },
];

const reviews = [
    { render: () => (
        <blockquote>
            Great and helpful service for your every want to record.
            <figcaption>— Tristan</figcaption>
        </blockquote>
    ) },
    { render: () => (
        <blockquote>
            Ridiculously cool place to make music. My son played snooker and got free tea as he waited for us to rehearse. We couldn't hear the band next door so good sound proofing and also nice acoustics. The room we were in was gorgeous
            <figcaption>— Jane</figcaption>
        </blockquote>
    ) },
    { render: () => (
        <blockquote>
            Fantastic place. The band and I had a fab time rehearsing here, even had a quick game of pool after! Great equipment and PA system with excellent effects. The acoustics almost mimics that of an actual venue whilst still being soundproofed enough to record vocals.
            <figcaption>— Nick</figcaption>
        </blockquote>
    ) },
];

const HomePage = () => {
    return (
        <Layout className="stack-col-3" size="md">
            <h2>Experience Worthing's most unique studio environment</h2>

            <Carousel height="500px" slides={images} />

            <div>
                <h3>About us</h3>

                <p>Ridiculously Cool is home to the most unique rehearsal and recording spaces available to hire in West Sussex - just seconds away from Worthing Train Station. We offer a wide range of services, from practice rooms and production studios to coworking office space and event hire.</p>

                <p>It’s no secret that great vibes make for great music, so we’ve designed atmospheric, affordable practice rooms that ditch the conventions of sterile studios. Get stuck into production (maybe mix that song you meant to release 3 years ago) or record your next hit single in our swanky vocal booth.</p>

                <p>Our rooms start at £12ph and are kitted out with enviable equipment such as high-quality PA systems with integrated subs, customisable mood lighting, drum kits and much more.</p>

                <p>Feel free to take a break during band practice to enjoy our snooker table, play some retro video games and make yourself a tea or coffee - included in the price. </p>

                <p>So what are you waiting for? Contact us now to secure a rehearsal or recording session at Ridiculously Cool.</p>
            </div>

            <div>
                <h3>What to expect</h3>

                <p>Our rehearsal rooms at Ridiculously Cool are spacious and customisable. Here are just a few examples of what you can expect:</p>

                <ul>
                    <li>Complete backline & PA systems in each rehearsal space</li>
                    <li>Instruments - vocal mics, drum kits, keyboards, analogue synths, bass and electric guitars</li>
                    <li>Professional equipment - amps, mic stands and many useful cables</li>
                    <li>Free Wi-Fi, parking, tea and coffee</li>
                    <li>Snooker table</li>
                    <li>Retro video games</li>
                    <li>Ambient mood lighting - can be synchronised to your music and colour-customisable to suit any mood</li>
                    <li>A kitchen with a fridge, oven, microwave and kettle</li>
                    <li>Comfortable sofas</li>
                    <li>Vocal booth and control room to rent</li>
                    <li>Space to hire for workshops, training and other events</li>
                    <li>A grand piano</li>
                    <li>Coworking desk space</li>
                </ul>

                <p>Take a look at what others have to say about us:</p>
                <Carousel slides={reviews}/>
            </div>
        </Layout>
    );
};

export default HomePage;
