import { Carousel, Grid, Layout, useBreakpoint } from '../Components/index.js';

const room1Images = [
    { clickToZoom: true, backgroundSrc: '/img/room-1/room-1a.jpg', height: '400px' },
    { clickToZoom: true, backgroundSrc: '/img/room-1/room-1b.jpg', height: '400px' },
    { clickToZoom: true, backgroundSrc: '/img/room-1/room-1c.jpg', height: '400px' },
    { clickToZoom: true, backgroundSrc: '/img/room-1/room-1d.jpg', height: '400px' },
    { clickToZoom: true, backgroundSrc: '/img/room-1/room-1e.jpg', height: '400px' },
    { clickToZoom: true, backgroundSrc: '/img/room-1/room-1f.jpg', height: '400px' },
];

const room2Images = [
    { clickToZoom: true, backgroundSrc: '/img/room-2/room-2a.jpg', height: '400px' },
    { clickToZoom: true, backgroundSrc: '/img/room-2/room-2b.jpg', height: '400px' },
    { clickToZoom: true, backgroundSrc: '/img/room-2/room-2c.jpg', height: '400px' },
    { clickToZoom: true, backgroundSrc: '/img/room-2/room-2d.jpg', height: '400px' },
    { clickToZoom: true, backgroundSrc: '/img/room-2/room-2e.jpg', height: '400px' },
];

const PracticeRoomsPage = () => {
    const { isGreaterThanOrEqualTo } = useBreakpoint();
    const gridWidth = isGreaterThanOrEqualTo('md') ? '575px' : undefined;

    return (
        <Layout className="stack-col-3" size="md">
            <h2>Rooms and prices</h2>

            <div id="blue-room">
                <Grid gap="md" justifyContent="center" width={gridWidth}>
                    <Carousel slides={room1Images} />

                    <div>
                        <h3>Blue room</h3>

                        <p>Suitable for bands of up to 10 people with a quadraphonic PA system and LED responsive speakers. Contact us to book our Blue Room for rehearsals.</p>
                        <p><strong>Rates:</strong> £16ph</p>
                        <p><strong>Size:</strong> 363 square foot</p>

                        <div><strong>Equipment:</strong></div>
                        <ul>
                            <li>Quadraphonic PA system with Alto TSL115 LED responsive speakers and integrated subwoofers</li>
                            <li>Yamaha MG16XU mixing console with 10 mic lines / 16 line inputs as well as 24 FX settings</li>
                            <li>Pearl drum kit</li>
                            <li>Amps: Egnater Tourmaster 4100 head paired with Egnater Tourmaster 412A cabinet, Peavey ValveKing 100 head and cabinet &amp; a Warwick Pro FET IV bass head paired with Ashdown 4x10 450 watt cabinet</li>
                            <li>SM58 &amp; SM57 microphones</li>
                        </ul>
                    </div>
                </Grid>
            </div>

            <div id="pink-room">
                <Grid gap="md" justifyContent="center" width={gridWidth}>
                    <div>
                        <h3>Pink room</h3>

                        <p>Suitable for bands of up to 7 people, fitted with a brand new PA system. Contact us to book our Pink Room.</p>
                        <p><strong>Rates:</strong> £14ph</p>
                        <p><strong>Size:</strong> 234 square foot</p>

                        <div><strong>Equipment:</strong></div>
                        <ul>
                            <li>Pearl drum kit</li>
                            <li>Yamaha MG16XU mixing console with 10 mic lines / 16 line inputs as well as 24 FX settings</li>
                            <li>SM58 &amp; SM57 microphones</li>
                            <li>PA system with integrated subwoofers</li>
                            <li>Amps: Marshall Bass State combo bass amp, Egnater Rebel-20 head twinned with Peavey cabinet, VOX AD100VT-XL combo &amp; a Marshall JCM800 lead combo</li>
                        </ul>
                    </div>

                    <Carousel slides={room2Images} />
                </Grid>
            </div>
        </Layout>
    );
};

export default PracticeRoomsPage;
