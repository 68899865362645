import { Grid, Image, Link, useBreakpoint } from '../index.js';

import css from './SocialLinks.module.scss';

const SocialLinks = () => {
    const { isGreaterThanOrEqualTo } = useBreakpoint();
    const iconSize = isGreaterThanOrEqualTo('md') ? '48px' : '32px';
    return (
        <div>
            <h3>Follow us on social media</h3>

            <Grid className={css.social} gap="lg" justifyContent="center">
                <div>
                    <Link external href="https://www.facebook.com/ridiculouslycoolstudios/">
                        <Image src="/img/social/fb1.png" size={iconSize} />
                    </Link>
                </div>
                <div>
                    <Link external href="https://www.instagram.com/ridiculouslycoolstudios/">
                        <Image src="/img/social/ig.png" size={iconSize} />
                    </Link>
                </div>
                {/* TODO: What's the linkedin link? */}
                {/* <div>
                    <Link external href="#">
                        <Image src="/img/social/li.png" height="48px" />
                    </Link>
                </div> */}
            </Grid>
        </div>
    );
};

export default SocialLinks;
